import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterEnum } from '../../types/filter.enum';

/**
 * FooterComponent is a standalone Angular component responsible for rendering the todos footer.
 */
@Component({
  selector: 'app-footer',
 // styleUrls: ['./footer.component.css'],
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class FooterComponent {
  /**
   * Injected instance of TodosService, used to interact with the todo list.
   */
  //todosService = inject(TodosService);

  // /**
  //  * The current filter signature from the TodosService.
  //  */
  // filterSig = this.todosService.filterSig;

  /**
   * Enum type for available filters (e.g., "All", "Active", etc.).
   */
  filterEnum = FilterEnum;

  /**
   * Computed property that returns the count of active todos (i.e., not completed).
   */
  // activeCount = computed(() => {
  //   return this.todosService.todosSig().filter((todo) => !todo.isCompleted)
  //     .length;
  // });

  /**
   * Computed property that indicates whether there are no todos in the list.
   */
  // noTodosClass = computed(() => this.todosService.todosSig().length === 0);
  // itemsLeftText = computed(
  //   () => `item${this.activeCount() !== 1 ? 's' : ''} left`
  // );

  // changeFilter(event: Event, filterName: FilterEnum): void {
  //   event.preventDefault();
  //   this.todosService.changeFilter(filterName);
  //   console.log('after changeFilter', this.todosService.filterSig());
  // }
}
