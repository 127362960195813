import { Component, OnInit, inject } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
//import { MainComponent } from './components/main/main.component';
//import { TodosService } from './services/todos.service';
//import { TodosFirebaseService } from './services/todosFirebase.service';
import { ArticleModalComponent } from './components/article-modal/article-modal.component';
import { ContactusComponent } from "../contactus/contactus.component";
import { ProjectsComponent } from "../projects/projects.component";
import { FaqComponent } from "../faq/faq.component";
import { TestimonialComponent } from "../testimonial/testimonial.component";
import { IntroductionComponent } from "../introduction/introduction.component";

/**
 * A Angular Component for managing todos.
 */
@Component({
  // The selector of this component
  selector: 'app-template',
  
  // The HTML template for this component
  templateUrl: './template.component.html',
  
  // This component is standalone, and it can be used without being part of a larger Angular module.
  standalone: true,
  imports: [HeaderComponent, FooterComponent, ArticleModalComponent, ContactusComponent, ProjectsComponent, FaqComponent, TestimonialComponent, IntroductionComponent],
})
/**
 * The TodosComponent class. It implements OnInit, which means it needs to implement the ngOnInit method.
 */
export class TemplateComponent implements OnInit {

  
  /**
   * Injected instance of TodosService.
   */
  //todosService = inject(TodosService);
  
  /**
   * Injected instance of TodosFirebaseService.
   */
  //todosFirebaseService = inject(TodosFirebaseService);

  /**
   * The initialization method for this component. It is called after the component's HTML template has been initialized.
   */
  ngOnInit(): void {}
    // Get all todos from the Firebase service and subscribe to its observable.
    //this.todosFirebaseService.getTodos().subscribe((todos) => {
      // Set the fetched todos in the TodosService.
      //this.todosService.todosSig.set(todos);
    //});
  }

