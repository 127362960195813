<div class="templateapp">
  <app-header></app-header>
  <app-introduction></app-introduction>
  <app-projects></app-projects>
  <!-- <app-main></app-main> -->
  <!-- <app-testimonial></app-testimonial> -->
  <app-faq></app-faq>
  <app-contactus></app-contactus>
  <app-footer></app-footer>
</div>
