import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import axios from 'axios';

@Component({
  selector: 'app-contactus',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './contactus.component.html',
  styleUrl: './contactus.component.css'
})
export class ContactusComponent {

  inquiryForm: FormGroup;
  
  constructor(private fb: FormBuilder) {
    this.inquiryForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      option: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  async onSubmit() {
    if (this.inquiryForm.valid) { 
      const formData = this.inquiryForm.value;
    
      try {
        // Replace with your Telegram bot token and chat ID
        const botToken = '7121440248:AAHM64ulUyIf03Gla0IPLN1RGkxPgJMatOs';
        const chatId = '-4211978428';
        
        const message = `
        New Inquiry:
        Name: ${formData.name}
        Email: ${formData.email}
        Phone: ${formData.phone}
        Service: ${formData.option}
        Message: ${formData.message}
        `;
        
        await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
          chat_id: chatId,
          text: message
        });
        
        console.log('Inquiry sent successfully');
        this.inquiryForm.reset();
      } catch (error) {
        console.error('Error sending inquiry:', error);
      }
    }
  }
}
