import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    // Remove all Firebase-related providers
    // Keep any other providers you need
  ]
};

// import { ApplicationConfig, importProvidersFrom } from '@angular/core';
// import { provideRouter } from '@angular/router';
// import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { routes } from './app.routes';

// const firebaseConfig = {
//   apiKey: "AIzaSyAPX_BUElUFt1JE7zDVaDqBu63NDo5MR0U",
//   authDomain: "angular-todo-5ef2e.firebaseapp.com",
//   projectId: "angular-todo-5ef2e",
//   storageBucket: "angular-todo-5ef2e.appspot.com",
//   messagingSenderId: "821074355532",
//   appId: "1:821074355532:web:8613e906b098b07a5a9be9"
// };

// export const appConfig: ApplicationConfig = {
//   providers: [
//     provideRouter(routes),
//     importProvidersFrom([
//       provideFirebaseApp(() => initializeApp(firebaseConfig)),
//       provideFirestore(() => getFirestore()),
//     ]),
//   ],
// };
