import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-article-modal',
  standalone: true,
  templateUrl: './article-modal.component.html',
  styleUrl: './article-modal.component.css'
})
export class ArticleModalComponent {
  constructor(public dialogRef: MatDialogRef<ArticleModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string, url: string }) { }

  close(): void {
    this.dialogRef.close();
  }
}
