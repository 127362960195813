import { Component, inject } from '@angular/core';
//import { TodosService } from '../../services/todos.service';
//import { TodosFirebaseService } from '../../services/todosFirebase.service';
import { NgFor, NgIf } from '@angular/common';

/**
 * HeaderComponent component.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  imports: [NgFor, NgIf],
  standalone: true,
})
export class HeaderComponent {
  menuItems = [
    // { name: 'ABOUT US', hasDropdown: true },
    // { name: 'WEB DEVELOPMENT', hasDropdown: true }
    // { name: 'DIGITAL MARKETING', hasDropdown: true },
    //{ name: 'PORTFOLIO', link: '/', hasDropdown: false },
       { name: 'BLOG', link: '/', hasDropdown: false },
       { name: 'CONTACT', link: '/', hasDropdown: false },
  ];

  languages = ['EN']; // Add more languages as needed
  selectedLanguage = 'EN';

  toggleLanguage(lang: string) {
    this.selectedLanguage = lang;
  }

  /**
   * Todo service instance injected via Angular's dependency injection.
   */
//  todosService = inject(TodosService);

  /**
   * Firebase todo service instance injected via Angular's dependency injection.
   */
  //todosFirebaseService = inject(TodosFirebaseService);

  /**
   * The text input value.
   */
  text: string = '';

  /**
   * 
   * @param URL of document
   */
  url: string = '';


  /**
   * Title of document.
   *  */ 
  title: string = '';

  /**
   * 
   * @param query : string
   * function onSearch for helping search for an item.
   */

  onSearch(query: string) {
    console.log('Searching for:', query);
    // Add your search logic here
  }

  /**
   * Event handler for text input changes.
   *
   * @param {Event} event - The event that triggered the change.
   */
  changeText(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.text = target.value;
  }

}
