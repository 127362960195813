<section class="bg-gray-100">
  <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
    <h1>Ready to Transform Your Online Presence?</h1>
    <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
      <div class="lg:col-span-2 lg:py-12">
        <p class="max-w-xl text-lg">
          Let's bring your digital vision to life! Whether you're dreaming of a sleek business website, a robust e-commerce platform, or a custom web application, we're here to turn your ideas into reality.
        </p>
        <p>
          Our expert team is just a message away. Tell us about your project, and we'll show you how we can create a web solution that not only meets your needs but exceeds your expectations.
        </p>
        <p>
          Fill out the form below, and let's start building something amazing together.
        </p>
      </div>

      <div class="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
        <form [formGroup]="inquiryForm" (ngSubmit)="onSubmit()" class="space-y-4">
          <div>
            <label class="sr-only" for="name">Name</label>
            <input
              class="w-full rounded-lg border-gray-200 p-3 text-sm"
              placeholder="Name"
              type="text"
              id="name"
              formControlName="name"
            />
          </div>

          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label class="sr-only" for="email">Email</label>
              <input
                class="w-full rounded-lg border-gray-200 p-3 text-sm"
                placeholder="Email address"
                type="email"
                id="email"
                formControlName="email"
              />
            </div>

            <div>
              <label class="sr-only" for="phone">Phone</label>
              <input
                class="w-full rounded-lg border-gray-200 p-3 text-sm"
                placeholder="Phone Number"
                type="tel"
                id="phone"
                formControlName="phone"
              />
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
            <div>
              <label
                for="Option1"
                class="block w-full cursor-pointer rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black has-[:checked]:border-black has-[:checked]:bg-black has-[:checked]:text-white"
                tabindex="0"
              >
                <input class="sr-only" id="Option1" type="radio" tabindex="-1" formControlName="option" value="Starter: WordPress Business Site" />
                <span class="text-sm"> Starter: WordPress Business Site </span>
              </label>
            </div>

            <div>
              <label
                for="Option2"
                class="block w-full cursor-pointer rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black has-[:checked]:border-black has-[:checked]:bg-black has-[:checked]:text-white"
                tabindex="0"
              >
                <input class="sr-only" id="Option2" type="radio" tabindex="-1" formControlName="option" value="Growth: Custom E-commerce Platform" />
                <span class="text-sm"> Growth: Custom E-commerce Platform </span>
              </label>
            </div>

            <div>
              <label
                for="Option3"
                class="block w-full cursor-pointer rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black has-[:checked]:border-black has-[:checked]:bg-black has-[:checked]:text-white"
                tabindex="0"
              >
                <input class="sr-only" id="Option3" type="radio" tabindex="-1" formControlName="option" value="Enterprise: Bespoke Web Application with Custom Dashboard" />
                <span class="text-sm"> Enterprise: Bespoke Web Application with Custom Dashboard </span>
              </label>
            </div>
          </div>

          <div>
            <label class="sr-only" for="message">Message</label>
            <textarea
              class="w-full rounded-lg border-gray-200 p-3 text-sm"
              placeholder="Message"
              rows="8"
              id="message"
              formControlName="message"
            ></textarea>
          </div>

          <div class="mt-4">
            <button
              type="submit"
              [disabled]="!inquiryForm.valid"
              class="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
            >
              Send Enquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>