<!-- Projects Section -->
<section class="py-16">
    <div class="container mx-auto px-4">
        <h2 class="text-4xl font-bold mb-8 text-center">Projects we've worked on.</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            <!-- Project Card -->
            <div class="bg-white rounded-lg shadow-md overflow-hidden">
                <img src="https://feedgenie.org/images/fg-hero.png" alt="Project Image" class="w-full h-64 object-cover">
                <div class="p-6">
                    <h3 class="text-2xl font-bold mb-4">Feedgenie</h3>
                    <p class="text-gray-700">All in one news and data aggregator for traders</p>
                    <a href="https://feedgenie.org" class="block text-blue-600 hover:underline mt-4"> Our latest project - Check it out! 🚀</a>
                </div>
            </div>
            <div class="bg-white rounded-lg shadow-md overflow-hidden">
                <img src="assets/cablelocators.png" alt="Project Image" class="w-full h-64 object-cover">
                <div class="p-6">
                    <h3 class="text-2xl font-bold mb-4">Cable Locators</h3>
                    <p class="text-gray-700">Specialists in the location of underground utilities.</p>
                    <a href="https://cablelocators.co.nz/" class="block text-blue-600 hover:underline mt-4">Check it out!</a>
                </div>
            </div>
            <div class="bg-white rounded-lg shadow-md overflow-hidden">
                <img src="assets/robinfernando.png" height="700" width="700" alt="Project Image" class="w-full h-64 object-cover">
                <div class="p-6">
                    <h3 class="text-2xl font-bold mb-4">Robin Fernando</h3>
                    <p class="text-gray-700">Concert Tour Company based in New Zealand promoting rap gigs in New Zealand.</p>
                    <a href="https://web.archive.org/web/20180512043304/http://robinfernando.com/" class="block text-blue-600 hover:underline mt-4">Check it out!</a>
                </div>
            </div>
            <!-- Repeat for other projects -->
        </div>
    </div>
</section>