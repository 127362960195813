/**
 * Import necessary Angular modules
 */
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TemplateComponent } from './todos/template.component';
import { Title } from '@angular/platform-browser';

/**
 * Define AppComponent as a standalone component
 * with the selector 'app-root', imports, templateUrl and styleUrls.
 */
@Component({
  /**
   * Selector for this component
   */
  selector: 'app-root',
  
  /**
   * This is a standalone component. No need to wrap it in another component.
   */
  standalone: true,
  
  /**
   * Import necessary modules (CommonModule, RouterOutlet and TodosComponent)
   */
  imports: [CommonModule, RouterOutlet, TemplateComponent],
  
  /**
   * Template URL for this component
   */
  //templateUrl: './app.component.html',

  /**
   * Template of html in file
   */
  template: `<app-template></app-template>`,
  
  /**
   * CSS file URLs for this component
   */
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  constructor(private titleService: Title) {
    // Set the title of the application
    this.setTitle('Oasis Agency');
  }

  /**
   * Method to set the title of the application
   * @param newTitle - The new title to set
   */
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}

