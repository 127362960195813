<div id="modalOverlay">
    <div class="modalPopup">
        <div class="headerBar">
            <!-- <img src="https://placehold.it/200x25/edcb04/333333/?text=LOGO" alt="Logo"> -->
        </div>
        <div class="modalContent">
                <h1>{{ data.title }}</h1>
                 <p>{{ data.text }} </p>
                 <a href="{{ data.url }}" target="_blank">Source</a>
                 <br>
                 <br>
                 <button mat-button (click)="close()" class="buttonStyle" id="button">Close</button>
        </div>
    </div>
</div>
