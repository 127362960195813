<!-- Header section with navigation and logo -->
<header class="bg-white shadow-md">
  <!-- Container for header content -->
  <div class="container mx-auto px-4">
    <!-- Flex container for logo and navigation -->
    <div class="flex justify-between items-center py-4">
      <!-- Logo and title container -->
      <div class="flex items-center">
        <!-- Image of the agency's logo -->
        <img src="assets/logo.jpg" height="50px" width="50px" alt="Oasis Agency" class="h-10">
        <!-- Title of the agency, displayed in a larger font size and color -->
        <h1 class="text-2xl font-bold text-green-500 ml-2">Oasis Agency</h1>
      </div>
      <!-- Navigation menu container -->
      <nav>
        <!-- Unordered list for navigation items -->
        <ul class="flex space-x-6">
          <!-- Loop through each item in the menuItems array and display as a list item -->
          <li *ngFor="let item of menuItems">
            <!-- Link to the current page, displaying the name of the item and a dropdown icon if applicable -->
            <!-- <a href="{{ item.link }}" class="text-gray-700 hover:text-blue-500 transition duration-300">
              {{ item.name }} -->
              <!-- Dropdown icon displayed only when the item has a dropdown -->
              <!-- <span *ngIf="item.hasDropdown" class="ml-1">▼</span>
            </a> -->
          </li>
        </ul>
      </nav>
      <!-- Language and search container -->
      <div class="flex items-center space-x-4">
        <!-- Relative container for the language dropdown -->
        <div class="relative">
          <!-- Button to toggle the language dropdown -->
          <!-- <button class="flex items-center text-gray-700 hover:text-blue-500">
            {{ selectedLanguage }} <span class="ml-1">▼</span>
          </button> -->
          <!-- Unordered list for languages, displayed as a dropdown when the button is clicked -->
          <ul class="absolute right-0 mt-2 bg-white shadow-md rounded-md hidden">
            <!-- Loop through each language in the languages array and display as an item -->
            <!-- <li *ngFor="let lang of languages"> -->
            <!-- Link to toggle the current language, displayed in a block layout with padding and hover effect -->
              <!-- <a href="#" (click)="toggleLanguage(lang)" class="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                {{ lang }}
              </a> -->
            <!-- </li> -->
          </ul>
        </div>
        <!-- Search button, displayed as an SVG icon -->
        <!-- <button class="text-gray-700 hover:text-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </button> -->
      </div>
    </div>
  </div>
</header>
<!-- <header class="blog-header" align="center">
  <div class="container">
    <div class="logo">
      <a routerLink="/"><img src="assets/logo.jpg" alt="Oasis Agency" width="65px"><h1>Oasis Agency</h1></a>
      <p>Creating connection and experiences since 2024.</p>
    </div>
    <nav class="navbar">
      <ul class="nav-links">
         <li><a routerLink="/" routerLinkActive="active">Home</a></li> -->
        <!-- <li><a routerLink="/about" routerLinkActive="active">About</a></li>
        <li><a routerLink="/blog" routerLinkActive="active">Blog</a></li>
        <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li> -->
     <!-- </ul>
    </nav> -->
    <!-- <div class="search-bar"> Refer to Two-way Data binding from https://www.coursera.org/learn/secure-full-stack-mean-developer/lecture/fOHBd/using-nglf-directive -->
      <!-- <input #searchInput type="text" placeholder="Search..." (keyup.enter)="onSearch(searchInput.value)">
      <button (click)="onSearch(searchInput.value)">Search</button>
    </div> 
  </div>
</header> -->
<!-- <header class="header">
  <h1>todos</h1>
  <input
    class="new-todo"
    placeholder="What needs to be done?"
    autoFocus
    [value]="text"
    (keyup)="changeText($event)"
    (keyup.enter)="addTodo()"
  />
</header> -->
