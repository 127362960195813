<div class="bg-gradient-to-r from-green-700 to-lime-800 text-white">
  <div class="container mx-auto px-4 py-16 sm:py-24">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      <div>
        <h1 class="text-4xl sm:text-5xl font-bold mb-4">
          We Build <span class="text-yellow-400">Stunning</span> Web Experiences
        </h1>
        <p class="text-xl mb-8">
          <!-- Transform your digital presence with our cutting-edge web solutions. We bring your ideas to life. -->
          Transforming digital visions into seamless realities, Oasis Agency delivers cutting-edge web solutions tailored to your unique business needs. Our expert team blends creativity with technical prowess to craft responsive, user-centric websites that elevate your brand and drive measurable results.
        </p>
        <!-- <button class="bg-yellow-400 text-purple-900 font-bold py-3 px-6 rounded-full hover:bg-yellow-300 transition duration-300">
          Get Started
        </button> -->
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="bg-white/10 p-6 rounded-lg backdrop-blur-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-yellow-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
          </svg>
          <h3 class="text-xl font-semibold mb-2">Custom Development</h3>
          <p>Tailored solutions to meet your unique needs</p>
        </div>
        <div class="bg-white/10 p-6 rounded-lg backdrop-blur-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-yellow-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          <h3 class="text-xl font-semibold mb-2">Responsive Design</h3>
          <p>Beautiful on every device and screen size</p>
        </div>
        <div class="bg-white/10 p-6 rounded-lg backdrop-blur-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-yellow-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
          <h3 class="text-xl font-semibold mb-2">Lightning Fast</h3>
          <p>Optimized for speed and performance</p>
        </div>
        <div class="bg-white/10 p-6 rounded-lg backdrop-blur-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-yellow-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
          </svg>
          <h3 class="text-xl font-semibold mb-2">Secure & Reliable</h3>
          <p>Built with best practices for maximum security</p>
        </div>
      </div>
    </div>
  </div>
</div>